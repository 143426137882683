import React, { useState } from 'react';
import TruncateMarkup from 'react-truncate-markup';

import * as S from './styles';

const Body = ({ renderDefault, renderTruncate, lines }) => {
  const [truncate, toogleTruncate] = useState(true);

  if (!truncate) {
    return (
      <S.Wrapper>
        <S.CommentBody>{renderDefault()}</S.CommentBody>
      </S.Wrapper>
    );
  }

  return (
    <TruncateMarkup
      lines={lines}
      ellipsis={
        <span>
          {`... `}
          <S.ReadMoreButton onClick={() => toogleTruncate(false)}>
            (more)
          </S.ReadMoreButton>
        </span>
      }
    >
      <S.Wrapper>
        <S.CommentBody>{renderTruncate()}</S.CommentBody>
      </S.Wrapper>
    </TruncateMarkup>
  );
};

export default Body;
