import React from 'react';
import { useSelector } from 'react-redux';
import { bannedImage } from 'theme';
import TruncateMarkup from 'app/components/post/TruncateMarkup';
import { markdownToPlainText } from 'app/utils/markdownFormat';
import * as S from './styles';

const PostDeletedDetail = () => {
  const notification = useSelector(
    state => state.modal.deletedPostNotifModalMeta,
  );

  return (
    <S.Container>
      <S.InnerContainer>
        <S.Content>
          <S.IllustrationWrapper>
            <img alt="hidden-fee" src={bannedImage.hiddenFees} />
          </S.IllustrationWrapper>
          <S.Header>{notification.message}</S.Header>

          <S.SubHeader>
            <TruncateMarkup
              lines={16}
              renderDefault={() => (
                <div>{markdownToPlainText(notification.post || '')}</div>
              )}
              renderTruncate={() => <span>{notification.post}</span>}
            />
          </S.SubHeader>
          <S.SupportMessage>
            View the community guidelines{' '}
            <a
              href="https://seedlyhelp.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{' '}
            or reach out to us at
            <a href="mailto:support@seedly.sg"> support@seedly.sg</a>.
          </S.SupportMessage>
        </S.Content>
      </S.InnerContainer>
    </S.Container>
  );
};

export default PostDeletedDetail;
