import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 170px;
`;

export const InnerContainer = styled.div`
  background-color: white;
  border-radius: 4px;
  max-width: 610px;
  margin: 0 auto;
`;

export const LogoWrapper = styled.div`
  padding: 24px 16px;

  img {
    height: 28px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 15px;
  padding: 30px;
  font-size: 14px;
  color: ${props => props.theme.colors.neutral5};
  > * {
    margin-bottom: 24px;
  }
`;

export const IllustrationWrapper = styled.div`
  img {
    height: 105px;
  }
`;

export const Header = styled.div`
  font-weight: bold;
  max-width: 305px;
  width: auto;
  color: $ ${props => props.theme.colors.neutral7};
`;
export const SubHeader = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.neutral7};
  background-color: ${props => props.theme.colors.neutral2};
  padding: 20px;
  margin: 0 20px 28px 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

export const SupportMessage = styled.div`
  width: 306px;
  padding: 16px 0;
  a {
    font-weight: bold;
    color: ${props => props.theme.colors.seedlyBlue};
  }
`;
