import { stateToMarkdown } from 'draft-js-export-markdown';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';

const regexForImage = /!\[[^\]]*\]\((.*?)\s*("(?:.*[^"])")?\s*\)/g;

// Remove multiple stuffs we dont support for markdown
export const formatMarkdown = markdown =>
  markdown
    .replace(/\\/g, '') // remove double backslash
    .replace(/<[^>]*>(.*?)<\/[^>]*>/g, `$1`) // strip html tags
    .replace(/~{1,}(.*?)~{1,}/g, `$1`) // remove strikethrough
    .replace(/`{1,}/g, '') // remove backticks for code blocks
    .replace(/\t+/g, '\n') // remove tabs for code blocks
    .replace(/\s{4,}/g, '\r') // remove space for code blocks
    .replace(/<(.*?)>/g, `$1`) // remove angle brackets for urls and emails
    .replace(/>{1,}/g, '') // remove blockquotes
    .replace(/(_{3,}|-{3,}|\*{3,})/g, '') // remove horizontal rule
    .replace(/\[\^(.*?)]/g, `^$1`) // remove footnote
    .replace(/(\[x])|(\[\s])/g, '') // remove task lists
    .replace(/[^\S\r\n]{2,}/g, ' ') // remove additional whitespace, tabs
    .trim();

// convert markdown to plain text
export const markdownToPlainText = markdown =>
  markdown
    .replace(/[\n|\r|*]/g, ' ')
    .replace(/[\u200B-\u200D\uFEFF]/g, ' ')
    .replace(/\+{2}(.*?)\+{2}/g, `$1`) // remove underline
    .replace(/_(.*?)_/g, `$1`) // remove italic
    .replace(/(\(http.*?)\)/g, '')
    .replace(/[*\][]/g, '') // remove links
    .replace(/#{1,}\s/g, '') // remove heading #
    .replace(/&quot;/g, `"`) // convert &quot;
    .replace(/&amp;/g, `&`) // convert &amp;
    .replace(/<[^>]*>(.*?)<\/[^>]*>/g, `$1`) // strip html tags
    .split(/\s{1,}/g)
    .join(' ')
    .trim();

export const removeImage = markdown =>
  markdown.replace(regexForImage, '').trim();

export const editorStateToFormattedText = contentState => {
  const markdown = stateToMarkdown(contentState);
  const formattedText = removeImage(formatMarkdown(markdown));
  return formattedText;
};

export const editorStateToFormattedTextWithImage = contentState => {
  const markdown = stateToMarkdown(contentState);
  const formattedText = formatMarkdown(markdown);
  return formattedText;
};

/**
 * @returns a ContentState object
 * @description to initialize a ContentState object for a new post
 */
const defaultContentState = (key = 'default') => {
  return convertFromRaw({
    entityMap: {},
    blocks: [
      {
        text: '',
        key,
        type: 'unstyled',
        entityRanges: [],
      },
    ],
  });
};

export const emptyEditorContentState = key => {
  return EditorState.createWithContent(defaultContentState(key));
};

/**
 * @param {string} text the markdown text that is retrieved for editing
 * @returns a EditorState instance
 * @description this will convert markdown with image to editorState
 */
export const contentEditedStateWithImage = text => {
  const editorState = EditorState.createWithContent(stateFromMarkdown(text));
  const contentState = editorState.getCurrentContent();
  const newBlockMap = contentState.getBlockMap().map(block => {
    const entityKey = block.getEntityAt(0);
    if (entityKey !== null) {
      const entityBlock = contentState.getEntity(entityKey);
      const entityType = entityBlock.getType();
      switch (entityType) {
        case 'IMAGE': {
          const newBlock = block.merge({
            type: 'atomic',
          });
          return newBlock;
        }
        default:
          return block;
      }
    }
    return block;
  });

  const newContentState = contentState.set('blockMap', newBlockMap);
  const raw = convertToRaw(newContentState);
  const newArrayBlk = [...raw.blocks];
  const finalContentState = convertFromRaw({
    entityMap: raw.entityMap,
    blocks: newArrayBlk,
  });

  return EditorState.createWithContent(finalContentState);
};

/**
 * @param {string} text the markdown text that is retrieved for editing
 * @param {string} key the key for contentState block
 * @returns a EditorState instance
 * @description a neccessary fix to preserve the markdown format and display it when a user edits
 */
export const contentEditedState = (text, key = 'default') => {
  if (!text) return emptyEditorContentState(key);
  const rawEditedState = convertToRaw(stateFromMarkdown(text));
  rawEditedState.blocks.forEach((block, index) => {
    block.key = `${key}-${index}`;
  });
  return EditorState.createWithContent(convertFromRaw(rawEditedState));
};
