import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useIsMobile from 'app/utils/hooks/useIsMobile';
import {
  openDeletedPostNotifModal as openDeletedPostNotifModalAction,
  closeDeletedPostNotifModal as closeDeletedPostNotifModalAction,
} from 'app/dispatcher/modalDispatcher';
import styled from 'styled-components';
import { imageUrl } from 'theme';
import { Modal } from 'app/seedly-component-library';

export const SeedlyLogo = styled.img`
  height: 36px;
  width: 74px;
  object-fit: contain;
`;

function withModal(WrappedComponent) {
  const ModalHoc = () => {
    const isDeletedPostNotifModalOpen = useSelector(
      state => state.modal.isDeletedPostNotifModalOpen,
    );
    const isMobile = useIsMobile();
    const dispatch = useDispatch();

    const openDeletedPostNotifModal = () => {
      dispatch(openDeletedPostNotifModalAction());
    };

    const closeDeletedPostNotifModal = () => {
      dispatch(closeDeletedPostNotifModalAction());
    };

    return (
      <Modal
        withCloseButton
        ariaHideApp={false}
        isOpen={isDeletedPostNotifModalOpen}
        shouldCloseOnOverlayClick={false}
        headerTitle="Post Deleted"
        renderHeaderLeft={() => (
          <SeedlyLogo src={imageUrl.seedlyLogo} alt="Seedly logo" />
        )}
        onRequestClose={closeDeletedPostNotifModal}
        bodyStyle={{ padding: '0' }}
      >
        <WrappedComponent
          isDeletedPostNotifModalOpen={isDeletedPostNotifModalOpen}
          isMobile={isMobile}
          openDeletedPostNotifModal={openDeletedPostNotifModal}
          closeDeletedPostNotifModal={closeDeletedPostNotifModal}
        />
      </Modal>
    );
  };

  return ModalHoc;
}

export default withModal;
