import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: normal;
`;

export const CommentBody = styled.div`
  width: 100%;
`;

export const ReadMoreButton = styled.a`
  cursor: pointer;
  color: ${props => props.theme.colors.seedlyBlue};

  &:hover {
    text-decoration: underline;
  }
`;
