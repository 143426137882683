import React from 'react';
import { useSelector } from 'react-redux';
import PostDeletedDetail from './PostDeletedDetail';
import withModal from './withModal';

const DeletedPostNotifModal = () => {
  const notification = useSelector(
    state => state.modal.deletedPostNotifModalMeta,
  );
  return <PostDeletedDetail notification={notification} />;
};

export default withModal(DeletedPostNotifModal);
